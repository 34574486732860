<template>
  <default-layout>
    <section class="education-sect">
      <h1>{{ $t("education.index.header") }}</h1>
      <p v-html="htmlContent"></p>
      <br />
    </section>
    <nav class="education__nav">
      <ul>
        <li id="education">
          {{ $t("education.nav.ctdh") }}
          <ul id="education__submenu">
            <li @click="changeUrl('specifications')">
              {{ $t("education.nav.specifications") }}
            </li>
            <li @click="changeUrl('rubrics')">
              {{ $t("education.nav.rubrics") }}
            </li>
          </ul>
        </li>
        <!--
        <li @click="changeUrl('ctsdh')" class="disable-program">
          {{ $t("education.nav.ctsdh") }}
        </li>
        <li @click="changeUrl('ctcd')" class="disable-program">
          {{ $t("education.nav.ctcd") }}
        </li>
        <li @click="changeUrl('ctlt')" class="disable-program">
          {{ $t("education.nav.ctlt") }}
        </li>
        -->
      </ul>
    </nav>
    <div class="education__card__container">
      <EducationCard
        v-for="data in dataList[$i18n.locale]"
        :information="data"
        :key="data.id"
      ></EducationCard>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layout/DefaultLayout";
import EducationCard from "@/components/EducationCard";
import htmlContent from "@/config/educontent.js";
export default {
  data() {
    return {
      dataList: [],
      htmlContent,
    };
  },
  methods: {
    async changeUrl(program) {
      const response = await this.$axios.get(`/api/posts/edu-${program}`);
      this.dataList = response.data;
    },
  },
  async created() {
    await this.changeUrl("ctdh");
  },
  components: {
    DefaultLayout,
    EducationCard,
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/education.scss";
.disable-program {
  background-color: rgb(161, 161, 161);
  pointer-events: none;
}
</style>
