export default `
<p>
  The Bachelor of Engineering in Software Engineering (BESE) programme offered
  by DoSE started in 2007 and the last updated in 2019. This programme is among
  a few undergraduate programmes in the field of software development for
  computers in information technology in Vietnam. The general information of the
  BESE programme is presented in Table 1.
</p>
<h6>Table 1: General information of the BESE programme</h6>
<table class="edu__table" id="table1" border>
  <tbody>
    <tr>
      <td width="274">
        <p>Programme name</p>
      </td>
      <td width="350">
        <p>Software Engineering</p>
      </td>
    </tr>
    <tr>
      <td width="274">
        <p>Programme code</p>
      </td>
      <td width="350">
        <p>7480103</p>
      </td>
    </tr>
    <tr>
      <td width="274">
        <p>In-charge training unit</p>
      </td>
      <td width="350">
        <p>College of Information and Communication Technology</p>
      </td>
    </tr>
    <tr>
      <td width="274">
        <p>University</p>
      </td>
      <td width="350">
        <p>Can Tho University</p>
      </td>
    </tr>
    <tr>
      <td width="274">
        <p>Award title</p>
      </td>
      <td width="350">
        <p>Engineer in Software Engineering</p>
      </td>
    </tr>
    <tr>
      <td width="274">
        <p>Opening year</p>
      </td>
      <td width="350">
        <p>Academic year 2007-2008</p>
      </td>
    </tr>
    <tr>
      <td width="274">
        <p>Number of graduated cohorts</p>
      </td>
      <td width="350">
        <p>09</p>
      </td>
    </tr>
    <tr>
      <td width="274">
        <p>Mode of training</p>
      </td>
      <td width="350">
        <p>Full-time, Regular</p>
      </td>
    </tr>
    <tr>
      <td width="274">
        <p>Training time</p>
      </td>
      <td width="350">
        <p>4.5 years (maximum 9.0 years)</p>
      </td>
    </tr>
    <tr>
      <td width="274">
        <p>Eligibility for graduation</p>
      </td>
      <td width="350">
        <p>
          Students must complete 156 credits, including 120 compulsory credits
          and 36 elective credits
        </p>
      </td>
    </tr>
    <tr>
      <td width="274">
        <p>Number of graduates until October, 2020</p>
      </td>
      <td width="350">
        <p>793</p>
      </td>
    </tr>
    <tr>
      <td width="274">
        <p>Number of students currently enrolled</p>
      </td>
      <td width="350">
        <p>712</p>
      </td>
    </tr>
    <tr>
      <td width="274">
        <p>Training language</p>
      </td>
      <td width="350">
        <p>Vietnamese</p>
      </td>
    </tr>
  </tbody>
</table>
<p>
  In order to be accredited as meeting the requirements of international
  criteria at programme level, the DoSE has performed 4 internal
  self-assessments for the BESE programme using 3 different sets of criteria as
  follows:
</p>
<ul>
  <li>
    In 2008, the BESE programme was self-assessed in accordance with the
    criteria of Vietnam Ministry of Education and Training (MOET)<em>.</em>
  </li>
  <li>
    In 2010, the BESE programme was self-assessed using the AUN-QA criteria
    Version 1<em>.</em>
  </li>
  <li>
    In 2015, with support from the experts of the Arizona State University, USA,
    the BESE programme was selected for mock assessment in accordance with the
    ABET criteria<em>.</em>
  </li>
  <li>
    In 2018, after significant improvements, the BESE programme continued to be
    self-assessed in accordance with AUN-QA criteria Version 3<em>.</em>
  </li>
</ul>
<p>
  To be accredited according to an international standard in education and
  training, CTU issued Decision 1254/QD-DHCT dated April 26, 2019 on the
  establishment of self-assessment team according to the AUN-QA standard for the
  BESE programme<em>.</em> The DoSE performed different activities to review the
  results of related work in 2018 and self-assess the BESE programme according
  to the AUN-QA criteria Version 3.
</p>
<h2><strong>Programme objectives </strong></h2>
<p>
  Based on the goals of Vietnam's higher education in Vietnam's Education Law
  (Regulation 39), CTU set its vision and mission. CICT&rsquo;s mission and
  vision are developed to be aligned with the CTU&rsquo;s mission and vision.
  The BESE programme has been built with programme objectives (POs) and expected
  learning outcomes (ELOs) in alignment with the vision and mission of both CTU
  and CICT.
</p>
<p>
  The Bachelor of Engineering in Software Engineering (BESE) programme has the
  following objectives:
</p>
<ul>
  <li>
    PO1: To train students to have health, ethics, professional responsibility
    and social responsibility.
  </li>
  <li>
    PO2: To equip students with fundamental knowledge of the theoretical basis
    of mathematics, science and technology relevant to Software Engineering.
  </li>
  <li>
    PO3: To equip students with solid specialized knowledge and skills to
    satisfy various tasks related to analysis, design, implementation, testing
    and maintenance of software systems, software project management and career
    development to high positions, and holding a leadership role.
  </li>
  <li>
    PO4: To equip students with foundation knowledge and skills to develop
    embedded &amp; IoT software systems, business software, or simulation
    software.
  </li>
  <li>
    PO5: To train students to have professional behavior, communication skills,
    teamwork skills, lifelong learning skills, scientific research, adaptability
    and working in the field of software engineering inside the country and
    abroad.
  </li>
</ul>
<h2><strong>Expected learning outcomes</strong></h2>
<p>
  In order to meet the programme&rsquo;s objectives, the BESE programme&rsquo;s
  ELOs are designed to systematically cover three domains namely knowledge,
  skills and attitudes. These ELOs cover general as well as specialized
  knowledge, skills as well as autonomy and responsibility, as described in
  Table 2. After completion, the graduates are able to:
</p>
<h6>Table 2: The BESE programme&rsquo;s ELOs</h6>
<table class="edu__table" border>
  <thead>
    <tr>
      <td width="104">
        <p><strong>Group</strong></p>
      </td>
      <td width="378">
        <p><strong>ELOs</strong></p>
      </td>
      <td width="76">
        <p><strong>Subject</strong></p>
        <p><strong>generic</strong></p>
        <p><strong>outcomes</strong></p>
      </td>
      <td width="85">
        <p><strong>Subject</strong></p>
        <p><strong>specific</strong></p>
        <p><strong>outcomes</strong></p>
      </td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td colspan="2" width="482">
        <p><strong>Knowledge</strong></p>
      </td>
      <td width="76">&nbsp;</td>
      <td width="85">&nbsp;</td>
    </tr>
    <tr>
      <td rowspan="3" width="104">
        <p><strong>General</strong></p>
        <p><strong>knowledge</strong></p>
      </td>
      <td width="378">
        <p>
          ELO 1: Show basic understanding of political science, social science
          and humanities; law; basic knowledge about physical education,
          national defence education.
        </p>
      </td>
      <td width="76">
        <p >&check;</p>
      </td>
      <td width="85">&nbsp;</td>
    </tr>
    <tr>
      <td width="378">
        <p>
          ELO 2: Demonstrate basic understanding of mathematics and fundamental
          of information technology to acquire professional knowledge and to
          study at higher levels.
        </p>
      </td>
      <td width="76">
        <p>&check;</p>
      </td>
      <td width="85">&nbsp;</td>
    </tr>
    <tr>
      <td width="378">
        <p>
          ELO 3: Demonstrate basic understanding of English&nbsp;or French,
          equivalent to level 3/6 of Vietnam's Framework of Foreign Language
          Proficiency (i.e., level B1 according to the European Framework).
        </p>
      </td>
      <td width="76">
        <p>&check;</p>
      </td>
      <td width="85">&nbsp;</td>
    </tr>
    <tr>
      <td rowspan="3" width="104">
        <p><strong>Fundamental knowledge</strong></p>
      </td>
      <td width="378">
        <p>
          ELO 4: Implement the basics of programming, object-oriented
          programming, web programming, data structure, algorithm analysis and
          design, graph theory, and artificial intelligence.
        </p>
      </td>
      <td width="76">&nbsp;</td>
      <td width="85">
        <p>&check;</p>
      </td>
    </tr>
    <tr>
      <td width="378">
        <p>
          ELO 5: Implement the basics of databases, modeling language, and
          analysis and design of information systems for organizations and
          enterprises.
        </p>
      </td>
      <td width="76">&nbsp;</td>
      <td width="85">
        <p>&check;</p>
      </td>
    </tr>
    <tr>
      <td width="378">
        <p>
          ELO 6: Implement the basics of computer architecture, operating
          system, system administration, and computer network.
        </p>
      </td>
      <td width="76">&nbsp;</td>
      <td width="85">
        <p>&check;</p>
      </td>
    </tr>
    <tr>
      <td rowspan="2" width="104">
        <p><strong>Specialized knowledge</strong></p>
      </td>
      <td width="378">
        <p>
          ELO 7: Apply in-depth knowledge of mathematic problem recognition,
          analysis, design, implementation, testing, quality assurance, software
          maintenance and software project management to software development.
        </p>
      </td>
      <td width="76">&nbsp;</td>
      <td width="85">
        <p>&check;</p>
      </td>
    </tr>
    <tr>
      <td width="378">
        <p>
          ELO 8: Apply knowledge of one of the three following areas: embedded
          software and IoT, business software, or simulation software to
          software development.
        </p>
      </td>
      <td width="76">&nbsp;</td>
      <td width="85">
        <p>&check;</p>
      </td>
    </tr>
    <tr>
      <td colspan="2" width="482">
        <p><strong>Skills</strong></p>
      </td>
      <td width="76">&nbsp;</td>
      <td width="85">&nbsp;</td>
    </tr>
    <tr>
      <td rowspan="3" width="104">
        <p><strong>Technical skills</strong></p>
      </td>
      <td width="378">
        <p>
          ELO 9: Demonstrate the following skills: problem identification,
          analysis, design and implementation, testing and quality assurance,
          project management, and maintaining a software system.
        </p>
      </td>
      <td width="76">&nbsp;</td>
      <td width="85">
        <p>&check;</p>
      </td>
    </tr>
    <tr>
      <td width="378">
        <p>
          ELO 10: Write standardized technical documentation for software
          development and maintenance phases.
        </p>
      </td>
      <td width="76">&nbsp;</td>
      <td width="85">
        <p>&check;</p>
      </td>
    </tr>
    <tr>
      <td width="378">
        <p>
          ELO 11: Professionally use tools&nbsp;that support&nbsp;all phases of
          the software development and maintenance process.
        </p>
      </td>
      <td width="76">&nbsp;</td>
      <td width="85">
        <p>&check;</p>
      </td>
    </tr>
    <tr>
      <td rowspan="3" width="104">
        <p><strong>Soft skills</strong></p>
      </td>
      <td width="378">
        <p>
          ELO 12: Use basic English or French and common office informatics
          software.
        </p>
      </td>
      <td width="76">
        <p>&check;</p>
      </td>
      <td width="85">&nbsp;</td>
    </tr>
    <tr>
      <td width="378">
        <p>
          ELO 13: Develop skills to adapt to the working environment and skills
          to organize, manage and effectively solve problems in a team working
          environment; innovation and start-up skills.
        </p>
      </td>
      <td width="76">
        <p>&check;</p>
      </td>
      <td width="85">&nbsp;</td>
    </tr>
    <tr>
      <td width="378">
        <p>
          ELO 14: Communicate effectively with team members, customers,
          supervisors, etc, in both written and spoken forms.
        </p>
      </td>
      <td width="76">
        <p>&check;</p>
      </td>
      <td width="85">&nbsp;</td>
    </tr>
    <tr>
      <td colspan="2" width="482">
        <p><strong>Autonomy and responsibility</strong></p>
      </td>
      <td width="76">&nbsp;</td>
      <td width="85">&nbsp;</td>
    </tr>
    <tr>
      <td rowspan="2" width="104">&nbsp;</td>
      <td width="378">
        <p>
          ELO 15: Demonstrate professionalism at work and ability
          of&nbsp;self-study and life-long learning.
        </p>
      </td>
      <td width="76">
        <p>&check;</p>
      </td>
      <td width="85">&nbsp;</td>
    </tr>
    <tr>
      <td width="378">
        <p>
          ELO 16: Demonstrate professional ethics and social responsibility.
        </p>
      </td>
      <td width="76">
        <p>&check;</p>
      </td>
      <td width="85">&nbsp;</td>
    </tr>
  </tbody>
</table>
<p>&nbsp;</p>
<h2>PROGRAMME SPECIFICATION</h2>
<p>
  The 2019 BESE curriculum consists of 156 credits with 120 required credits and
  36 elective ones, allocated to the general education, fundamental and
  specialized blocks, as shown in Figure 1. These blocks are composed of fifteen
  clusters<em>.</em> The percentage of credits of each cluster is shown in
  Figure 1.
</p>
<img src="/edu.png" />
<h5>Figure 1: The clusters of BESE curriculum</h5>
<img src="/edu-2.jpg" />
`;
