<template>
  <div class="education__card">
    <a :href="information.detail | stripHTML" target="_blank"
      ><p>{{ information.title }}</p></a
    >
  </div>
</template>

<script>
export default {
  props: {
    information: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>